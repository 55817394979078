import React from "react";
import PropTypes from "prop-types";


class PreLoader extends React.Component {

	static propTypes = {
		size: PropTypes.number,
	}


	static defaultProps = {
		size: 3
	}


	render() {
		return (
			<div className='d-flex justify-content-center align-items-center' style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 2,
			}}>
				<div className='d-flex justify-content-center '>
					<div className="spinner-border" style={{ width: '3rem', height: '3rem', color: '#42d0d2', opacity: 0.8 }} role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			</div>
		);
	}
}

export default PreLoader;
