import { navigate } from "@reach/router";
import NodeCache from "node-cache" ;
// MobX
import {action, observable} from 'mobx';
// Models
import gameModel from "src/models/game.model";

const myCache = new NodeCache();


class AuthModel {

	refreshTokenPromise = null;


	@observable F2A = false;
	@observable isF2A = false;
	@observable transactions = null;
	@observable user = null;
	@observable avatar = null;

	@action setAvatar = (avatar) => this.avatar = avatar? `${process.env.REACT_APP_API_ENDPOINT}/media/${avatar}` : avatar;

	async getTransactions() {
		const transactions = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/finance/transactions/my/`, { cache: false });
		if(transactions.error) return;
		this.transactions = transactions;
	}


	createUser(user = {}) {
		this.user = user;
	}


	sendF2ACode = async (username) => {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/2fa/email/activate/`, {
			method: "POST",
			body: {
				email: username || gameModel.userInfo?.user?.username
			}
		});
		if(response.error) return false

		return true
	}


	logIn = async ({ username, password })=> {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/`, {
			method: "POST",
			body: { username, password }
		});
		if(response.error) return "Invalid Email or password!";

		// F2A New
		if (response.ephemeral_token) {
			this.F2A = response.ephemeral_token;
			navigate('/F2A');
			return;
		}

		// F2A
		if(response.token) {
			this.F2A = response.token;
			navigate('/F2A');
			return;
		}

		this.F2A = false;
		localStorage.setItem('refreshToken', response.refresh);
		localStorage.setItem('accessToken', response.access);

		this.user = {
			...this.user,
			refresh: response.refresh,
			access: response.access
		};
	}

	checkIsF2A = async () => {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/2fa/mfa/user-active-methods/`, { cache: false });
		if(response.length === 0) return this.isF2A = false

		this.isF2A = true
	}

	sendDeactivateF2ACode = async () => {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/2fa/code/request/`, {
			method: "POST",
			body: {
				method: "email"
			}
		});
		if(response.error) return false;

		return true;
	}

	confirmDeactiveF2ACode = async (code) => {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/2fa/email/deactivate/`, {
			method: "POST",
			body: {
				code
			}
		});
		if(response.error) return "Incorrect code!"

		this.isF2A = false;
		return true
	}

	confirmActivateF2A = async (code) => {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/2fa/email/activate/confirm/`, {
			method: "POST",
			body: {
				code,
			}
		});
		if(!response.backup_codes) return "Incorrect code!"

		this.isF2A = true
	}


	logInF2A = async (code)=> {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/code/`, {
			method: "POST",
			body: {
				code,
				ephemeral_token: this.F2A
			}
		});
		if(response.error) return "Incorrect code!";

		this.F2A = false;
		localStorage.setItem('refreshToken', response.refresh);

		this.user = {
			...this.user,
			refresh: response.refresh,
			access: response.access
		};
	}


	logOut = async ()=> {
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('accessToken');
		this.user = null;
		this.F2A = false;
		this.isF2A = false;
		this.transactions = null;
		Object.keys(myCache.data).forEach(key => myCache.del(key)); // Clear cache
		gameModel.clearGameModel();
		window.location.reload(); // Sockets problem
		//navigate(`/`);
	}


	signUp = async ({ username, password, email, invite })=> {
		const quiz = localStorage.getItem("quizResult") || 0;
		const cryptoGameSessionId = localStorage.getItem("crypto_game_session_id") || undefined;

		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/create/`, {
			method: "POST",
			body: { username, password, email, invite, quiz, bitcoin_game_session_id: cryptoGameSessionId }
		});

		if(response.error) return JSON.parse(response.error);

		return false;
	}


	changePassword = async (body)=> {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/change/password/`, {
			method: "PUT",
			body
		});

		if(response.error) return response.error;
	};


	sendPasswordToEmail = async (email)=> {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/forgot/password/`, {
			method: "POST",
			body: { email }
		});

		return response;
	};

	refreshToken = async ()=> {
		let response = await window.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/users/auth/refresh/`, {
			method: "POST",
			body: JSON.stringify({ refresh: this.user?.refresh }),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${this.user?.access}`
			}
		});
		response = await response.json();

		if(!response.access) return this.logOut();

		localStorage.setItem('accessToken', response.access);
		this.user.access = response.access;
	}

	sendAvatar = async (avatar) => {
		const formData = new FormData();
		formData.append("photo", avatar);

		let response = await window.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/clients/upload_photo/`, {
			method: "POST",
			body: formData,
			headers: {
				// "Content-Type": 'multipart/form-data',
				"processData": false,
				"contentType": false,
				Authorization: `Bearer ${this.user?.access}`
			}
		});

		response = await response.json();
		return response;
	}

	removeAvatar = async () => {
		let response = await this.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/clients/delete_photo/`, {
			method: "DELETE"
		});

		return response
	}

	fetching = {};
	async fetch(url = '', {needStatusResponse, ...options} = {}) {
		options = {
			method: 'GET',
			headers: {},
			cache: true,
			body: null,
			...options
		};

		// Return cached
		if(options.method === 'GET' && myCache.get(url) && options.cache) {
			this.fetching[url] = false;
			return myCache.get(url);
		}

		// Fetching new token
		await this.refreshTokenPromise;

		// Prevent requests spam
		if(this.fetching[url]) {
			await this.fetching[url];
			return { error: ' ' }; // Prevent spam
		}

		// Store [fetch] promise
		this.fetching[url] = fetch(url, {
			method: options.method,
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				...options.headers,
				...this.user?.access && { Authorization: `Bearer ${this.user.access}` },
			},
			...options.body && { body: JSON.stringify(options.body) }
		});

		// Resolve promise
		let response = await this.fetching[url];
		// Refresh token error 401
		if(response.status === 401) {
			if(!this.refreshTokenPromise) this.refreshTokenPromise = this.refreshToken();
			await this.refreshTokenPromise;
			this.refreshTokenPromise = null;
			this.fetching[url] = false;
			if(!this.user) return navigate('/'); // LogOut (bad [refresh] token)
			return this.fetch(url, options);
		}

		let result = {};
		// Error handling
		try {
			result = response.status === 200 || response.status === 201 ? await response.json() : { error: await response.text() };
		} catch(error) {
			result = { error: `Error: ${error}` };
		}
		if(result.error === '') result.error = 'Server error';
		if(result.error && result.error.detail) result.error = result.error.detail;

		// Save to cache
		if(options.method === 'GET' && !result.error) myCache.set( url, result, 180000);

		// Fetching is finished
		this.fetching[url] = false;

		if(needStatusResponse) return {...response, responseStatus: response.status};
		return result;
	}
}


export default new AuthModel();
