import React from "react";
import { Router, Redirect } from "@reach/router";
import { observer } from "mobx-react";
// Models
import authModel from "src/models/auth.model";
// Preloader
import PreLoader from 'src/components/PreLoader.component';
// Footer
const Footer = React.lazy(() => import("src/components/Footer.component"));
// Pages
const FAQPage = React.lazy(()=> import("src/components/pages/FAQPage.component"));
const ForgotPasswordPage = React.lazy(()=> import("src/components/pages/ForgotPasswordPage.component"));
const HomePage = React.lazy(()=> import("src/components/pages/HomePage.component"));
const GamePage = React.lazy(()=> import("src/components/pages/GamePage.component"));
const GameResultPage = React.lazy(()=> import("src/components/pages/GameResultPage.component"));
const ProfilePage = React.lazy(()=> import("src/components/pages/ProfilePage.component"));
const LoginPage = React.lazy(()=> import("src/components/pages/LoginPage.component"));
const F2APage = React.lazy(()=> import("src/components/pages/F2APage.component"));
const RegistrationPage = React.lazy(()=> import("src/components/pages/RegistrationPage.component"));
const PredictionPage = React.lazy(()=> import("src/components/pages/PredictionPage.component"));
const StockPage = React.lazy(()=> import("src/components/pages/StockPage.component"));
const QuizPage = React.lazy(() => import("src/components/pages/QuizPage.component"));
const BlitzPage = React.lazy(() => import("src/components/pages/BlitzPage.component"));
const QuizSharePage = React.lazy(() => import("src/components/pages/QuizSharePage.component"));
const FootballPage = React.lazy(() => import("src/components/pages/FootballPage.component"));
const BitcoinPage = React.lazy(() => import("src/components/pages/BitcoinPage.component"));
const UpOrDownGamePage = React.lazy(() => import("src/components/pages/UpOrDownGamePage.component"));

let NotFound = () => <div>404</div>;


@observer
class RouterComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            withFooter: true,
        }
    }

    setWithFooter = (value) => {
        this.setState({
            withFooter: value
        })
    }

    render() {
        return (
            <React.Suspense fallback={ <PreLoader /> }>
                { authModel.user ?
                    <Router>
                        <HomePage path="/" index={'0'} />
                        <HomePage path="/trader" index={'1'} />
                        <HomePage path="/investor" index={'2'} />

                        <GamePage path="/game" />
                        <GameResultPage path="/game-result/:gid" />
                        <FAQPage path="/FAQ" />
                        <ProfilePage path="/profile" />

                        <PredictionPage path='/game-prediction/:gid' />
                        <StockPage path='/:ticker' />

                        <Redirect from="/login" to="/game" noThrow />
                        <Redirect from="/registration" to='/game' noThrow />

                        <BlitzPage path="/blitz" />
                        <QuizSharePage path="/share/:point" />
                        <FootballPage path="/events/:eventId" />
                        <BitcoinPage path="/btc" />

                        <UpOrDownGamePage path="/bitcoingame" setWithFooter={this.setWithFooter} />

                        <NotFound default />
                    </Router>
                    :
                    <Router>
                        <HomePage path="/" index={'0'} />
                        <HomePage path="/trader" index={'1'} />
                        <HomePage path="/investor" index={'2'} />
                        
                        <LoginPage path="/login" />

                        { authModel.F2A ?
                            <F2APage path="/F2A" />
                            :
                            <Redirect from="/F2A" to="/login" noThrow />
                        }

                        <ForgotPasswordPage path="/forgot-password" />
                        <RegistrationPage path="/registration" />
                        <QuizPage path="/quiz" />
                        <QuizSharePage path="/share/:point" />

                        <UpOrDownGamePage path="/bitcoingame" setWithFooter={this.setWithFooter} />

                        <Redirect from="*" to="/" noThrow />
                    </Router>
                }
                {/* {!this.withFooter && <Footer />} */}
                <Footer />
            </React.Suspense>
        )
    }
}


export default RouterComponent;
