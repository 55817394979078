import authModel from "src/models/auth.model";
import stockModel from 'src/models/stock.model';

let SOCKET = null;
let isOpen = false;


function startWebsocket() {
	//console.log('SOCKET | open');
	SOCKET = new WebSocket(process.env.REACT_APP_SOCKET)

	// Connection opened
	SOCKET.addEventListener('open', function (event) {
		isOpen = true;
	});

    // Listen for messages
	SOCKET.addEventListener('message', function (event) {
		let messages = event.data.replace(/~m~\d+~m~+/g, (match)=> '|');
		messages = messages.split('|').filter(Boolean).map(json => JSON.parse(json));
		messages.forEach(message => {
			const stockName = message.p?.[1]?.n;
			if(!stockName) return // TODO;

			const stock = message.p?.[1].v;
			if(!stock) return // TODO;

            const [market, ticker] = stockName.split(':');
            stockModel.updateStockValue(ticker, {
            	lp: stock?.lp,
				chp: stock.chp,
				ch: stock.ch,
            });
		});
	});

    
	SOCKET.addEventListener('close', function (event) {
		isOpen = false;
		console.log('SOCKET | close');
		setTimeout(()=> {
			startWebsocket();
			setTimeout(()=> {
				console.log('authModel.user', authModel.user)
				if(authModel.user) stockModel.reSendStocks();
			}, 2000);
		}, 2000);
	});
}

startWebsocket();


export function connectToStocks(stocks = []) {
	if (isOpen) {
		//console.log('SOCKET | send stocks');
		SOCKET.send(JSON.stringify(stocks));
	} else {
		console.log('SOCKET | send stocks error');
	}
}
