// MobX
import { observable, action } from 'mobx';
// Models
import authModel from 'src/models/auth.model';
import blitzModel from 'src/models/blitz.model';
import { connectToStocks } from 'src/socket_blitz';

class StockModel {
    @observable stocks = [];
    @observable stock = {};

    async getStocks() {
        const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/stocks/list/`);
        this.stocks = response;

        connectToStocks(this.stocks.map(stock => `${stock.market}:${stock.ticker}`));
    }

    async getStockByTicker(ticker) {
        const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/stocks/${ticker}/`);

        if (response.status) {
            this.stock[ticker] = response.data;
        }
    }

    updateStockValue(ticker, {lp, ch, chp}) {
        let stock = this.stocks.find(_ => _.ticker == ticker);
        if (stock) {
            if (lp) {
                stock.lp = lp;
            }
            
            if (ch) {
                stock.ch = ch;
            }

            if (chp) {
                stock.chp = chp;
            }
        }
    }

    reSendStocks() {
		if(blitzModel.blitzData) connectToStocks(this.stocks.map(stock => `${stock.market}:${stock.ticker}`)); // 'market:ticker'
	}
}

export default new StockModel();