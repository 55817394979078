import authModel from "./../models/auth.model";
import gameModel from "./../models/game.model";
import {action, computed, observable} from "mobx";
import moment from "moment";
import {connectToStocks} from "../socket_blitz";
import stockModel from './stock.model';

class BlitzModel {
    @observable blitzShort = "short";
    @observable blitzLong = "long";
    @observable blitzTypeActive = this.blitzShort; //short, long
    @observable blitzData = {
        [this.blitzShort]: null,
        [this.blitzLong]: null
    };
    @observable modalError = null;
    // @observable activeStock = null;
    @observable gamesList = [];

    @observable usersValue = {
        [this.blitzShort]: "",
        [this.blitzLong]: "",
    };

    @observable endOfBlitzGame = {
        [this.blitzShort]: null,
        [this.blitzLong]: null,
    };//null;//{time: timestamp, minute: string, second: string, timerInterval: number}
    @observable endOfBlitz = {
        [this.blitzShort]: null,
        [this.blitzLong]: null,
    };//{time: timestamp, hour: string, minute: string, second: string, timerInterval: number}
    @observable nowFinishedBlitz = null;
    @observable startOfBlitz = {
        [this.blitzShort]: "",
        [this.blitzLong]: "",
    };

    @observable winner = {
        [this.blitzShort]: null,
        [this.blitzLong]: null,
    };

    @observable selectedStock = {
        [this.blitzShort]: 0,
        [this.blitzLong]: 0,
    };

    @computed get activeStock() { 
        const stock = stockModel.stocks?.find(_ => _.id === this.selectedStock[this.blitzTypeActive]) || {};

        return {
            id: stock?.id,
            name: stock?.ticker,
            stock_value: stock?.lp || stock?.price
        }
    }

    @observable winnerStockResult = {
        [this.blitzShort]: null,
        [this.blitzLong]: null,
    };

    @computed get bettedStock() {
        const stock = stockModel?.stocks?.find(stock => stock.id === this.blitzData[[this.blitzTypeActive]]?.bets?.stock?.id);
        return stock;        
    }

    getServerIdBlitz(blitzType) {
        return blitzType === this.blitzShort? 1 : 2;
    }

    @computed get isStartBlitz() {
        return Date.now() >= this.startOfBlitz[this.blitzTypeActive].time && Date.now() < this.endOfBlitz[this.blitzTypeActive].time;
    }

    @computed get isShortStartBlitz() {
        return Date.now() >= this.startOfBlitz[this.blitzShort].time && Date.now() < this.endOfBlitz[this.blitzShort].time;
    }

    @action calculateEndOfGame = (stop = false, type, blitzType = null) => {
        if(this[type][blitzType]) {
            let dateOther;
            if(type === "endOfBlitzGame") {
                if(blitzType === this.blitzLong) {
                    dateOther = moment(this[type][blitzType].time).add(1, "hours");
                    dateOther = moment(dateOther).add(1, "seconds");
                }else if(blitzType === this.blitzShort) {
                    dateOther = moment(this[type][blitzType].time).add(5, "minutes");
                    dateOther = moment(dateOther).add(1, "seconds");
                }
            }else {
                dateOther = moment(this[type][blitzType].time);
            }

            const dateNow = moment(Date.now());

            const days = dateOther.diff(dateNow, "days");
            dateNow.add(days, "days");
            const hours = dateOther.diff(dateNow, "hours");
            dateNow.add(hours, "hours");
            const minutes = dateOther.diff(dateNow, "minutes");
            dateNow.add(minutes, "minutes")
            const seconds = dateOther.diff(dateNow, "seconds");

            if(type === "startOfBlitz") {
                this[type][blitzType].day = addZero(days);
            }
            if(blitzType === this.blitzLong || type === "endOfBlitz" || type === "startOfBlitz") {
                this[type][blitzType].hour = addZero(hours);
            }
            this[type][blitzType].minute = addZero(minutes);
            this[type][blitzType].second = addZero(seconds);
            if(!stop && (+this[type][blitzType].days > 0 || +this[type][blitzType].hour > 0 || +this[type][blitzType].minute > 0 || +this[type][blitzType].second >= 0)) {
                this[type][blitzType].timerInterval = setTimeout(() => this.calculateEndOfGame(false, type, blitzType), 1000);
            }else {
                clearInterval(this[type][blitzType].timerInterval);
                if(type === "endOfBlitzGame") {
                    this.getDataForResult(blitzType);
                    //this.endOfBlitzGame = null;
                }
                if(type === "endOfBlitz") {
                    this.nowFinishedBlitz = true;
                }
            }
        }

        function addZero(numb) {
            return +numb < 10 && Math.sign(+numb) === 1? "0" + numb : numb;
        }
    }
    @action clearDataUnmounted = () => {
        this.clearBlitzGame(this.blitzShort);
        this.clearBlitzGame(this.blitzLong);

        this.nowFinishedBlitz = false;
    }
    clearBlitzGame(blitzType) {
        this.calculateEndOfGame(true, "endOfBlitzGame", blitzType);
        this.winner[blitzType] = null;
        this.endOfBlitzGame[blitzType] = null;
        this.endOfBlitz[blitzType] = null;
        this.calculateEndOfGame(true, "endOfBlitz", blitzType);
    }
    @action setWinner = (winner, blitzType) => this.winner[blitzType] = winner;
    @action setModalError = (errorType) => this.modalError = modalErrors[errorType];
    @action clearModalError = () => this.modalError = null;
    @action setActiveStock = (id, blitzType) => this.selectedStock[blitzType] = id;
    @action upValue = (type, blitzType) => {
        if(type === "max") {
            if(+this.usersValue[blitzType] < 0) {
                this.usersValue[blitzType] = 1;
            }else {
                this.usersValue[blitzType] = this.fixUsersValue(+this.usersValue[blitzType] + 1);
                this.checkZero(type, "plus", blitzType);
            }
        }
        if(type === "min") {
            this.usersValue[blitzType] = this.fixUsersValue(+this.usersValue[blitzType] + 0.1);
            this.checkZero(type, "plus", blitzType);
        }
    }
    @action downValue = (type, blitzType) => {
        if(type === "max") {
            if(+this.usersValue[blitzType] > 0) {
                this.usersValue[blitzType] = -1;
            }else {
                this.usersValue[blitzType] = this.fixUsersValue(+this.usersValue[blitzType]- 1);
                this.checkZero(type, "minus", blitzType);
            }
        }
        if(type === "min") {
            this.usersValue[blitzType] = this.fixUsersValue(+this.usersValue[blitzType] - 0.1);
            this.checkZero(type, "minus", blitzType);
        }
    }
    @action resetBlitz(blitzType) {
        this.setWinner(null, blitzType);
        this.endOfBlitzGame[blitzType] = null;
        this.selectedStock[blitzType] = 0;
        this.usersValue[blitzType] = '';
    }
    @action changeType(type) {
        this.blitzTypeActive = type;
    }

    setUserValue(prediction, blitzType) {
        if (prediction.endsWith('-') && prediction.length > 1) {
			prediction = prediction.slice(1);
		}

		if (prediction.includes(',')) {
			prediction = prediction.replace(',', '.');
		}

		if (prediction.endsWith('.') && prediction.split('.').length > 2) {
			prediction = prediction.slice(0, prediction.length - 1);
		}

		if (!prediction.endsWith('.') && prediction !== '-' && prediction != '-0') {
			if (prediction) {
				prediction = parseFloat(prediction).toFixed(1);
				let matchPrediction = prediction.match(/(^-?\d+\.?(\d{0,1}))+/);
				if (matchPrediction) {
					prediction = matchPrediction[0];

					prediction = parseFloat(prediction).toString();
				}
			}
		}

		if(isFinite(prediction) && prediction >= 100) prediction = '99.9';
		if(isFinite(prediction) && prediction <= -100) prediction = '-99.9';

		if (prediction == 'NaN') {
			prediction = '0';
		}

        this.usersValue[blitzType] = prediction;
    }

    fixUsersValue = (value) => {
        return Number.isInteger(value)? value : value.toFixed(1);
    }

    checkZero = (type, direction, blitzType) => {
        const value = Number(this.usersValue);

        if(value === 0 || value === 0.0) {
            if(type === "max") {
                this.usersValue[blitzType] = direction === "plus"? this.usersValue[blitzType] + 1 : this.usersValue[blitzType] - 1;
            }
            if(type === "min") {
                this.usersValue[blitzType] = direction === "plus"? this.fixUsersValue(this.usersValue[blitzType] + 0.1) : this.fixUsersValue(this.usersValue[blitzType] - 0.1);
            }
        }
    }

    sendGameData = async (blitzType) => {
        const userPredict = this.activeStock.stock_value * +this.usersValue[blitzType] / 100;
        const data = {
            "blitz_id": this.blitzData[blitzType].id,
            "stock_id": this.activeStock.id,
            "predict": +this.usersValue[blitzType],
            "predict_usd": this.activeStock.stock_value + userPredict,
            "blitz_type": blitzType === this.blitzShort? 1 : 2,
        }
        const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/blitz/bet/`, {method: "POST", body: data, cache: false});

        if(response.status) {
            this.blitzData[blitzType] = response.data;

            this.endOfBlitzGame[blitzType] = {time: new Date(response.data.bets.time_bet).getTime(),}
            this.calculateEndOfGame(false, "endOfBlitzGame", blitzType);
        }
    }

    getBlitzData = async (blitzType) => {
        if(!gameModel.game || !gameModel.game.id) {
            await gameModel.getGame();
        }else {
            let response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/blitz/game/${gameModel.game.id}/?blitz_type=${this.getServerIdBlitz(blitzType)}`, {cache: false});
            if (Date.now() > new Date(response.time_end).getTime()) {
                response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/blitz/game/${+gameModel.game.id + 1}/?blitz_type=${this.getServerIdBlitz(blitzType)}`, {cache: false});
            }

            if(response.bets?.active) {
                this.endOfBlitzGame[blitzType] = {time: new Date(response.bets.time_bet).getTime(),};
                this.calculateEndOfGame(false, "endOfBlitzGame", blitzType);

                connectToStocks([`${response.bets.stock.market}:${response.bets.stock.ticker}`]);
            }

            this.endOfBlitz[blitzType] = {time: new Date(response.time_end).getTime(),}
            this.startOfBlitz[blitzType] = {time: new Date(response.time_start).getTime(),}

            this.blitzData[blitzType] = response;

            if(Date.now() < this.startOfBlitz.time) {
                this.calculateEndOfGame(true, "startOfBlitz", blitzType);
                this.calculateEndOfGame(false, "startOfBlitz", blitzType);
            }
        }
    }

    getDataForResult = async (blitzType) => {
        let id = this.blitzData[blitzType]?.bets?.id;
        const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/blitz/result/${id}/`, {cache: false});
        if(response.error) return;
        this.winner[blitzType] = response.is_winner? "User" : "AI";
        this.gamesList.unshift(response);
        if(response?.transaction?.client?.balance) {
            gameModel.setNewBalance(response?.transaction?.client?.balance);
        }
        this.winnerStockResult[blitzType] = {...this.bettedStock};
        this.endOfBlitzGame[blitzType] = null;
    }

    getGamesList = async () => {
        let response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/blitz/result/list/`, {cache: false});
        response.sort(function(a,b){
            return new Date(b.transaction.time_add).getTime() - new Date(a.transaction.time_add).getTime();
        });
        this.gamesList = response;
    }

    getPartFromPercent = (value, percent) => {
        return (+value * +percent / 100).toFixed(2);
    }
}

const modalErrors = {
    blitzDenied: {
        title: "BLITZ-GAME WITH AI NOT AVAILABLE",
        description: null,
        button: "OK",
    },
    generalDenied: {
        title: "GAME IS ON, MAKING BETS IS CLOSED",
        description: "Blitz-game with AI is on\nCheck your skills!",
        button: "Start blitz-game with AI",
    },
    limitPoints: {
        title: null,
        description: "Unfortunately, you don't have enough GOS on your balance to participate in a Blitz-Game with AI. Earn GOS in daily games or in a referral program and get access to a Blitz-Game with AI",
        button: "OK",
    }
}

export default new BlitzModel();
