// Router
import Router from "src/Router.component";

function App() {

	return (
		<>
			<Router />

			<div id="modals-root" />
		</>
	);
}

export default App;
