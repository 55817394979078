import React from 'react';
import ReactDOM from 'react-dom';
// Mock API
import "src/api";
// Utils
import isIncognitoMode from "src/utils/isIncognitoMode.util";
// Socket
import "src/socket";
// Styles
import "src/styles/style.css";
import "src/styles/new-styles.css";
import "src/styles/custom-style.css";
import "src/styles/quiz-style.css";
import "src/styles/instruction-style.css";
import "src/styles/blitz-style.css";
import "src/styles/quiz-share-style.css";
import "src/styles/football-style.css";
import "src/styles/bitcoin-style.css";
import "src/styles/home-style.css";
import "src/styles/other-style.css";
import "src/styles/upOrDown-style.css";
// Models
import authModel from "src/models/auth.model";
// Components
import App from 'src/App';


async function setupApp() {
	const isIncognito = await isIncognitoMode();

	if(!isIncognito) {
		const access = window.localStorage.getItem('accessToken');
		const refresh = window.localStorage.getItem('refreshToken');
		if(access && refresh) authModel.createUser({ access, refresh }); // Try logIn
	} else {
		// Incognito mode
	}

	ReactDOM.render(
		<React.StrictMode>
			<App/>
		</React.StrictMode>,
		document.getElementById('root')
	);
}

setupApp();
