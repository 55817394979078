import { connectToStocks } from "src/socket";
// MobX
import {action, computed, observable} from 'mobx';
// Models
import authModel from 'src/models/auth.model';
import blitzModel from "./blitz.model";


class GameModel {

	playCard = observable.map();
	@observable game = null;
	@observable featureGame = null;
	@observable userInfo = {
		balance: '',
		top: ''
	};
	@observable gameResults = {
		// gid: gameResults
	};
	@observable finishedGameResults = {

	};
	@observable predictions = [];
	@observable isGameStarted = false;
	@observable previewModalIsShowed = true;
	@observable isEditable = true

	@computed get gameStartDateMs() { return new Date(this.game?.time_start).getTime(); };
	@computed get gameFeatureStartDateMs() { return new Date(this.featureGame?.time_start).getTime(); };
	@computed get gameEndDateMs() { return new Date(this.game?.time_end).getTime(); };

	@action setNewBalance = (balance) => this.userInfo.balance = balance;

	checkIsGameStarted() { 
		this.isGameStarted = this.gameStartDateMs <= (new Date).getTime();

		if (this.isGameStarted  && this.playCard.size !== this.game?.stocks.length) {
			this.playCard.clear();
		}

		return this.isGameStarted;
	}
	onShowPreviewModal = () => { return this.previewModalIsShowed = true }


	clearGameModel() {
		this.game = null;
	    this.featureGame = null;
	    this.userInfo = {
			balance: '',
			top: ''
		}
		this.gameResults = {};
	}


	updatePlayCard({ stockId, prediction, betValue, betValueUSD }) {
		const data = {
			game_stock_id: stockId, 
			prediction, 
			bet_value: betValue, 
			bet_value_usd: betValueUSD
		}

		this.playCard.set(stockId, {
			...this.playCard.get(stockId),
			...data
		});
	}


	updateStock(changes = {}) {
		if(!this.game) return; // LogOut case
		this.game.stocks.find(stock => stock.market === changes.market && stock.ticker === changes.ticker);

		this.game.stocks = this.game.stocks.map(stock => {
			return stock.market === changes.market && stock.ticker === changes.ticker ?
				{ ...stock, ...changes }
				:
				stock
		});
	}

	requestWithdraw = async (amount) => {
		const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/clients/withdraw/`, {
			method: "POST",
			body: {
				amount
			}
		});

		return !response.error;
	}

	getPredictionByGameId = async (gameid = 'current') => {
		const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/game_predict/${gameid}/`, {
			method: "GET",
		});

		if (!response.status) return response.description;
		this.predictions = response.data;
	}

	buyAIForecast = async () => {
		const response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/buy/`, {
			method: "POST"
		})

		if (response.error) return response.error
	}


	reSendStocks = ()=> {
		if(this.game) connectToStocks(this.game.stocks.map(stock => `${stock.market}:${stock.ticker}`)); // 'market:ticker'
	}


	async getGame() {
		if(this.game) return; // Prevent duplicate socket send stocks
		const game = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/game/`);
		if(game.error) return;

		// TODO: empty game
		this.game = game;

		if(game.bets.length > 0) {
			this.isEditable = false

			game.bets.forEach(item => {
				this.playCard.set(item.game_stock.id, {
					stockId: item.game_stock.id,
					prediction: +item.bet_value,
					betValue: +item.bet_value,
					betValueUSD: +item.bet_value_usd,
				})
			})
		}

		connectToStocks(this.game.stocks.map(stock => `${stock.market}:${stock.ticker}`)); // 'market:ticker'

		await blitzModel.getBlitzData(blitzModel.blitzShort);
		await blitzModel.getBlitzData(blitzModel.blitzLong);
	}


	async getGameResults(gid = 'my') {
		const gameResults = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/finance/results/${gid}/`);
		if(gameResults.error) return;
		this.gameResults[gid] = gameResults;
	}

	async getFinishedGameResults(gid) {
		const gameResults = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/game/${gid}/`);
		const gameResultsPlace = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/game_result/${gid}/`);
		if(gameResults.error) return;
		if(gameResultsPlace.error) return;
		
		this.finishedGameResults[gid] = {
			...gameResults,
			totalResultValue: gameResults.current_user.total_result_value,
			game: gameResultsPlace.list?.[0]?.game,
			rate: gameResultsPlace.list?.[0]?.rate,
			prize: gameResultsPlace.list?.[0]?.transaction?.amount,
		};
	}

	async getLeaderBoard(numDays) {
		const res = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/game_rating/?days=${numDays}`)
		return {
			user: res.current_user,
			leaders: res.data
		}
	}

	async getUserInfo(userId = 'me', cache=true) {
		let response = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/clients/id/${userId}/`, { cache });
		if(response.error) return;
		this.userInfo = response;
		if(response.photo) {
			authModel.setAvatar(response.photo.replace(/.*?\/media\//, ""));
		}
	}


	async getFeatureGameInfo() {
		let game = await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/next/`);
		if(game.error) return;
		if(!game.time_start) return;
		this.featureGame = game;
	}


	async savePlayCard(playCard) {
		playCard = playCard.map(card => ({
			prediction: parseFloat(card.prediction),
			"game_stock_id": +(card.stockId === undefined ? card.game_stock_id : card.stockId),
			"bet_value": +(card.prediction === undefined ? card.betValue : card.prediction),
			"bet_value_usd": +(card.bet_value_usd === undefined ? card.betValueUSD : card.bet_value_usd),
		}));

		if (!playCard.every(_ => isFinite(_.bet_value_usd) && isFinite(_.bet_value) && _.game_stock_id)) {
			throw new Error('Invalid playerCard data');
		}


		await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/bet/`, {
			method: "POST",
			body: {
				"game_id": this.game.id,
				"data": playCard
			}
		});
	}


	async getPrizesWeek() {
		return await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/games/winners/week/`);
	}

	async getDragonInfo() {
		return await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/tournaments/dragon/`, {cache: false});
	}

	async enterDragonGame(tournament_id) {
		return await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/tournaments/enter/`, {
			method: "POST",
			body: {
				tournament_id
			},
			cache: false,
		});
	}

	async getUpOrDownGameInfo() {
		return await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/cryptocurrency/info/BTC/`, {cache: false});
	}

	async setUpOrDownGameBet(cryptocurrency_id, prediction) {
		return await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/cryptocurrency/crypto-bet/`, {
			method: "POST",			
			body: {
				cryptocurrency_id,
				prediction,
			},
			cache: false,
		});
	}

	async getUpOrDownGameBet(sessionId) {
		return await authModel.fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/cryptocurrency/crypto-bet/${sessionId}/`, {cache: false});
	}
}


export default new GameModel();
