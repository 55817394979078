import authModel from "src/models/auth.model";
import gameModel from "src/models/game.model";

let SOCKET = null;
let isOpen = false;


export function startWebsocket(setData) {
	console.log('SOCKET | open');

	SOCKET = new WebSocket(process.env.REACT_APP_SOCKET);

	// Connection opened
	SOCKET.addEventListener('open', function (event) {
		isOpen = true;

		// SOCKET.send(
		// 	JSON.stringify({
		// 		auth: `${localStorage.getItem('accessToken')}`,
		// 	})
		// )
	});


	// Listen for messages
	SOCKET.addEventListener('message', function (event) {
		const messages = JSON.parse(event.data);

		messages.forEach(message => {
			const stock = message;
			const newStockInfo = {
				market: stock.market,
				ticker: stock.ticker,
				...stock.price && { lp: +stock.price.toFixed(5) },
				...stock.change && { ch: +stock.change.toFixed(5) }, // %
				...stock.change_in_percent && { chp: +stock.change_in_percent.toFixed(5) }, // %
			};

			setData?.(newStockInfo);
			gameModel.updateStock(newStockInfo);
		})
	});


	SOCKET.addEventListener('close', function (event) {
		isOpen = false;
		// console.log('SOCKET | close', event);
		setTimeout(()=> {
			startWebsocket(setData);
			setTimeout(()=> {
				if(authModel.user) gameModel.reSendStocks();
			}, 2000);
		}, 2000);
	});
}

startWebsocket();


async function sleep(time=1000) {
	return new Promise(resolve => {
		setTimeout(resolve, time);
	})
}

async function waitSocketConnect() {
	if (isOpen) {
		return true;
	}

	await sleep(100);
	return waitSocketConnect();
}

export async function connectToStocks(stocks = []) {
	await waitSocketConnect();

	if (isOpen) {
		//console.log('SOCKET | send stocks');
		SOCKET.send(JSON.stringify(stocks));
	} else {
		console.log('SOCKET | send stocks error');
	}
}
